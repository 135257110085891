export default {
  loop: 1,
  mirror: 1,
  drop: 1,
  hide: 1,
  speedFactor: 2,
  hideDelay: 3000,
  // hideDelay: 0,
  texts: [
    // `w`,
    // `articles`,
    `welcome to nelabs.dev`,
    `č ķ ģ`
    // `abcdefghijk`,
    // `lorem ipsum`,
  ],
  offsetAbs: 200
};
